import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    recaptchaSiteKey: '',
    activeLink: '',
    services: [
      {
        id: 'financial-reporting',
        title: 'Financial Reporting',
        description:
          'We help our clients leverage their existing and new technologies to build best-of-breed financial reporting to capture total net worth and risk within their portfolios - no matter how complex their ownership structures.',
        list: [
          {
            title: 'A holistic approach to reporting wealth',
            description:
              "In today's rapidly evolving wealth landscape, financial reporting has evolved beyond traditional asset class reporting. Whether the portfolio is under your management or that of another, we can provide a comprehensive understanding of all assets, thereby ensuring clear visibility and a complete overview of your financial position in any market condition."
          },
          {
            title: 'With an ever-changing wealth landscape',
            description:
              'At Blackmar, we offer tailored services to improve your financial wellbeing, with customized financial reporting that aligns with your specific needs and preferences.'
          }
        ]
      },
      {
        id: 'financial-expert-services',
        title: 'Financial Expert Services',
        description:
          'Blackmar works behind the scenes as an extension of your team to provide operational and technology expertise.',
        list: [
          {
            title:
              'Expert service support for all your financial reporting technology needs',
            description:
              'Our consultants bring a wealth of knowledge in both technology and system management for a diverse range of money managers, family offices, and institutions. We work collaboratively with your organization to create and maintain custom solutions that optimize your strengths, improve quality, and streamline operations.'
          },
          {
            title: 'The golden <br/> standard for support',
            description:
              'Our modern approach to middle office tasks negates the need for an internal team, utilizing our expertise and technological capabilities to achieve greater efficiency, while our customizable partnership caters to your specific requirements.'
          },
          {
            title: 'Why Blackmar is the best fit for you',
            description:
              'At the heart of our company is the commitment to exceptional financial reporting services that deliver clear insights into the details, a principle we uphold in our expanded services, where we take pride in excelling at even the most complex tasks.'
          }
        ]
      }
    ],
    roles: {
      default: [
        'entrepreneur',
        'product manager',
        'graphic designer',
        'financial analyst',
        'technical recruiter',
        'ux/ui designer',
        'front-end developer',
        'back-end developer',
        'other'
      ],
      services: [
        'wealth manager',
        'financial analyst',
        'financial consultant',
        'other'
      ],
      workWithUs: [
        'ux/ui designer',
        'product manager',
        'graphic designer',
        'financial analyst',
        'technical recruiter',
        'front-end developer',
        'back-end developer',
        'devops engineer',
        'QA engineer',
        'software engineer in test',
        'other'
      ]
    },
    lookingFor: {
      default: ['financial consulting', 'a new career step', 'other'],
      services: [
        'financial consulting',
        'a new career step',
        'software development',
        'other'
      ],
      workWithUs: ['', '']
    }
  },
  getters: {},
  mutations: {
    SET_ACTIVE_LINK (state, value) {
      state.activeLink = value;
    }
  },
  actions: {
    async send_message ({ commit, state }, form) {
      grecaptcha.ready(async () =>  {
        try {
          const token = await  grecaptcha.execute(state.recaptchaSiteKey, { action: 'submit' });
          const domain = 'https://altairws.net/api/v1';
          const emailParams = {
            senderEmail: form.company_email,
            recaptchaToken: token,
            publicEmailType: 'BlackmarContactUs',
            firstName: form.first_name,
            lastName: form.last_name,
            message: form.message,
          }
          await axios.post(`${domain}/messaging/public-email/send`, emailParams);
        } catch (err) {
          //
        }
      });
    }
  },
  modules: {}
});
