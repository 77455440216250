import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mitt from 'mitt';
const emitter = mitt();

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import './css/main.scss';
import './css/typography.scss';

const app = createApp(App);
app.provide('emitter', emitter);
app.use(store).use(router).mount('#app');
