import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout"),
    children: [
      { path: "/", name: "home", component: () => import("../views/Home") },
      {
        path: "/work-with-us",
        name: "Work with Us",
        meta: {
          // we use this to highlight the route in the main header
          highlight: true,
        },
        component: () => import("../views/WorkWithUs"),
      },
      {
        path: "/services/:id",
        name: "Services",
        meta: {
          highlight: true,
        },
        component: () => import("../views/Services"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/Home"),
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "instant" };
  },
});

export default router;
